<template>
  <div>
    <portal to="page-top-title">Offer #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'offers-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/offers/${id}`" data-prop="offer" resp-prop="data" loader @request-after="requestAfter">
      <template slot="content">
        <row>
          <column-info title="Name">{{ offer.name }}</column-info>
          <column-info title="Lifetime">{{ offer.lifetime | secondsToTime }}</column-info>
          <column-info title="Created At">{{ offer.created_at }}</column-info>
          <column-info title="Updated At">{{ offer.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="URL" :md="6">
            <a :href="offer.url" target="_blank">{{ offer.url }}</a>
          </column-info>
          <column-info title="Redirect URL" :md="6">
            <nullable :value="offer.redirect_url">
              <template slot="value">
                <a :href="offer.redirect_url" target="_blank">{{ offer.redirect_url }}</a>
              </template>
            </nullable>
          </column-info>
        </row>
        <row>
          <column-info title="Compare Clicker Data">
            <badge-active :value="offer.compare_clicker_data"/>
          </column-info>
          <column-info title="Active">
            <badge-active :value="offer.active"/>
          </column-info>
        </row>
        <row>
          <column-info title="Campaigns">
            <badge title="Total">{{ offer.campaigns_count }}</badge>
          </column-info>
          <column-info title="Messages">
            <stats-messages :stats="offerStats"/>
          </column-info>
          <column-info title="Clicks">
            <stats-clicks :stats="offerStats"/>
          </column-info>
          <column-info title="Revenues">
            <stats-revenues :stats="offerStats"/>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import Btn from '@/views/components/simple/Btn'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Badge from '@/views/components/simple/Badge'
import Nullable from '@/views/components/simple/Nullable'
import secondsToTime from '@/modules/filters/secondsToTime'
import StatsMessages from '@/views/components/stats/Messages'
import StatsClicks from '@/views/components/stats/Clicks'
import StatsRevenues from '@/views/components/stats/Revenues'

export default {
  name: 'OffersSingle',
  metaInfo () {
    return { title: 'Offer #' + this.id }
  },
  components: {
    BoxSingle,
    Btn,
    BadgeActive,
    Badge,
    Nullable,
    StatsRevenues,
    StatsClicks,
    StatsMessages
  },
  computed: {
    id () { return this.$route.params.id }
  },
  filters: {
    secondsToTime
  },
  data () {
    return {
      offer: {},
      offerStats: {}
    }
  },
  methods: {
    requestAfter () {
      this.$http
        .get('/offers/stats', { params: { ids: [this.id] } })
        .then((res) => {
          const { data: { data } } = res
          const id = Number(this.id)

          this.offerStats = data.find((stats) => stats.id === id)
        })
    }
  }
}
</script>
